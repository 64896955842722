import React from "react";
import Layout from "../../components/layout";
import { Container, H1 } from "../../styles/ui";
import styled from "@emotion/styled";
import Contact from "../../components/contact";
import { StaticImage } from "gatsby-plugin-image";

const EddigiMunkaink: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="marketing"
      title="Referenciák"
      description="Marketing Referenciák"
    >
      <Wrapper>
        <H1>
          Eddigi <span>munkáink</span>
        </H1>
        <br />
        <br />

        <Munka>
          <div>
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/marketingmunkak/loccitane.jpg"
              alt="L’occitane - Termék fotózás"
            />
          </div>
          <div>
            <h3>L’occitane - Termék fotózás</h3>
            <p>
              Eszti a fantasztikus fotós a csapatunkban rendszeresen készít a
              L’occitane számára fotókat a termékeikról, amelyek a weblapukon
              megtalálhatóak. Szuper termékek és kifinomult stílus jellemzi
              őket, amivel szivesen és könnyen azonosulunk.
            </p>
            <ul>
              <li>Termék fotózás</li>
            </ul>
          </div>
        </Munka>
        <Munka>
          <div>
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/marketingmunkak/dotka.jpg"
              alt="Dotka Shop - Google hirdetés"
            />
          </div>
          <div>
            <h3>Dotka Shop - Google hirdetés</h3>
            <p>
              Egy újonnan induló vállalkozás beindítása mindig izgalmas feladat!
              Ebben az esetben is egy szuper termékeket forgalmazó webshopról
              van szó, amivel könnyedén tudunk azonosulni és ez nagyon fontos
              számunkra! Google hirdetés kezelése a feladatunk és SEO. Grafikai
              támogatást nyújtunk a display megjelenésekhez többek között.
            </p>
            <ul>
              <li>Google hirdetések megtervezése-stratégia alkotás</li>
              <li>Grafikai munkák</li>
              <li>Display hirdetések létrehozása és készítése</li>
              <li>Kulcsszó hirdetések létrehozása és kezelése</li>
              <li>Weblap management, SEO</li>
            </ul>
          </div>
        </Munka>
        <Munka>
          <div>
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/marketingmunkak/csaszardental.jpg"
              alt="Császár Dental - Havi online marketing csomag"
            />
          </div>
          <div>
            <h3>Császár Dental - Havi online marketing csomag</h3>
            <p>
              Komplex havi szintű együttműködés keretében az összes kommunikáció
              és marketing területet lefedjük. Minden hónapban az általunk
              kialakított stratégia alpján haladva készítünk anyagokat számukra.
              Havonta egy fotózást csinálunk. A képeket különböző utómunkálatok
              során készítjük elő a lehető legjobb eredmény érdekében. A
              grafikus és a fotós elkészíti, majd a social media manager a
              megfelelő tematika alapján posztolja az algoritmusok ismerete
              alapján, hogy a lehető leghatékonyabb organikus elérést generálja.
              Videók, image filmek és storyk készítése hasonló képp zajlik. Az
              elkészített anyagokat kampányok és nyereményjátékok során is
              felhasználjuk. A hirdetések beállítását elvégezzük és folyamatosan
              monitorozzuk. Google hirdetéseiket is egy kézben tartjuk, amely a
              rendelő forgalmához nagyban hozzátesz. A honlap SEO, webdesign és
              üzemeltető feladatait szintúgy ellátjuk.
            </p>
            <ul>
              <li>
                Social media management és hirdetéskezelés (Instagramm,
                Facebook, Tik-Tok)
              </li>
              <li>Szövegek megírása</li>
              <li>Havi fotózás</li>
              <li>Grafikai munkák</li>
              <li>Google hirdetések kezelése</li>
              <li>Weblap management, SEO, webdesign, üzemeltetés</li>
            </ul>
          </div>
        </Munka>
        <Munka>
          <div>
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/marketingmunkak/lisa501.jpg"
              alt="Lisa vitorlás csapat - Social média marketing"
            />
          </div>
          <div>
            <h3>Lisa vitorlás csapat - Social média marketing</h3>
            <p>
              Litkey Farkas többször nyerte meg a Lisa hajóval a Kékszalagot!
              Magyarország egyik leghíresebb hajója. A csapat célja, hogy social
              médián keresztül rajongóit és követőit egy közösséggé kovácsolja,
              akikkel folyamatosan kapcsolatban állhatnak. Az általunk indított
              oldalak kezdetben nulla felhasználóval rendelkeztek. Az Instagram
              oldalukat 2 év után több mint 31.000 ember követi, amelyet
              optimalizált és költséghatékony megoldásokkal értünk el, valamint
              minőségi tartalmak készítésével.
            </p>
            <ul>
              <li>Instagram/FB aktivitás növelése</li>
              <li>Minőségi tartalmak készítése</li>
              <li>Képek-videók-szövegírás-interjú</li>
            </ul>
          </div>
        </Munka>
        <Munka>
          <div>
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/marketingmunkak/kajakkenu.jpg"
              alt="Magyar Kajak-Kenu Szövetség - Social média marketing"
            />
          </div>
          <div>
            <h3>Magyar Kajak-Kenu Szövetség - Social média marketing</h3>
            <p>
              Állandó marketingesként a Magyar Kajak-Kenu Szövetség különböző
              marketing területével foglalkoztunk. A nemzeti és nemzetközi,
              legmagasabb szintű megjelenések és kommunikációs feladatok
              elvégzése volt a feladatunk.
            </p>
            <ul>
              <li>Országos méretű kampányok stratégiájának elkészítése</li>
              <li>Felmérések, piackutatások végzése</li>
              <li>Online/offline grafikai munkák készítése és gyártása</li>
              <li>Interjúk, sajtótájékoztatók lebonyolítása, PR</li>
              <li>
                Kapcsolat tartása és folyamatos közös munka az összes hazai
                sajtó orgánummal
              </li>
              <li>Sajtó megjelenések elkészítése</li>
              <li>
                Google és Social média hirdetések kezelése. A Facebook 48.000
                fős követői számára tartalom készítése
              </li>
            </ul>
          </div>
        </Munka>
        <Munka>
          <div>
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/marketingmunkak/mvsz.jpg"
              alt="Steering the Course"
            />
          </div>
          <div>
            <h3>Magyar Vitorlás szövetség - Steering the course kampány</h3>
            <p>
              Állandó partnerünkkel a Magyar Vitorlás Szövetséggel és a
              Nemzetközi Vitorlás Szövetséggel közreműködve idén első alkalommal
              megrendezésre került a Steering the Course kampány, amelynek
              célja, hogy a nők körében is népszerűsítse a vitorlás sportágat. A
              program már az első évben több száz hölgyet aktivizált, akik közül
              sokan megmérettették magukat az STC versenyén is. A program másik
              célja a férfiak és nők együttműködését ösztönözni a sportágban.
            </p>
            <ul>
              <li>Branding</li>
              <li>Stratégiai tervezés</li>
              <li>Online/offline arculati anyagok készítése</li>
              <li>Social média kampány</li>
              <li>Fotós és videós anyagok</li>
              <li>Interjúk</li>
            </ul>
          </div>
        </Munka>
      </Wrapper>
      <Contact variant="marketing" />
    </Layout>
  );
};

export default EddigiMunkaink;

const Wrapper = styled(Container)`
  margin: 160px 0 96px 0;
`;

const Munka = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-bottom: 64px;

  & > div:first-of-type {
    border-radius: 20px;
  }

  h3 {
    margin-bottom: 32px;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  ul,
  li {
    position: relative;
    list-style: none;
    padding: 0;
    color: var(--color-middle-gray);
    padding-left: 1rem;
  }
  li:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 999px;
    background-color: var(--color-primary);
    left: 0;
    top: 50%;
    transform: translate(-150%, -50%);
  }
`;
